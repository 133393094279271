import { Component, OnInit, ViewChild  } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CacProductService } from '../../../services-http/cac-product/cac-product.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import { QuotationService } from '../../../services-http/quotation/quotation.service';
import { debounceTime } from "rxjs/operators";
import {environment} from '../../../../environments/environment';
import * as moment from 'moment';


@Component({
  selector: 'app-fire-insure',
  templateUrl: './fire-insure.component.html',
  styleUrls: ['./fire-insure.component.scss']
})
export class FireInsureComponent implements OnInit {
  @ViewChild('basicModal4') basicModal4: ModalDirective;
  @ViewChild('basicModal5') basicModal5: ModalDirective;
  @ViewChild('basicModal3') basicModal3: ModalDirective;


  login_id:any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cacProduct:CacProductService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private IssuePolicy: IssuePolicyService,
    private quotation: QuotationService,
  ) {
      route.params.subscribe(val => {
        this.login_id=val.login_id;
        this.quoteId=val.quoteId;
        this.dialogIndex = 0;
        this.productInfo={points:{credit:0,regular:0}};
        this.details = [];
        this.benefeciary = [];
        this.show = false;
        this.waivedCommission = 0;
        this.totalCalCommission = 0;
        this.productId =  val.id;
        this.ageValidationMsg='';

    });
  }

  items: MenuItem[];
  dialogIndex:number = 0;
  assuredDetailsMemberAssociate: FormGroup;
  assuredDetailsCorporate:FormGroup;
  occupancyDetails:FormGroup;
  benefitForm:FormGroup;
  memberType:any = "memberAssociateMember";
  selectedType:any;
  benefeciary:any = [];
  productId:any;
  productInfo:any={points:{credit:0,regular:0}};
  show:boolean =false;
  details:any = []
  User = JSON.parse(localStorage.getItem('currentUser'));
  waivedCommission:any = 0;
  totalCalCommission:number = 0;
  dateNow = new Date().getFullYear();
  birthdate:any;
  ageValidationMsg ="";
  declarationConfirm=false;
  paymentType:any= '';
  HDF:FormGroup;
  quoteId:any;
  netpremium:any=0;
  url=this.router.url;
  furl;
  posting_type:any=0;
  clicked:boolean=false;
  quoteData:any;
  prev_policy_no:any ='';
  new_business:boolean = false; 
  unit:any=1;
  isLoading:boolean = false;
  searchitems:any={};
  searchResult:any = [];
  searchListShow:boolean = false;
  referer:any ='';
  formData = new FormData();
  effectivity_date =  moment(new Date).format('YYYY-MM-DD');;



  optionsSelect = [
      { value: 'Monthly', label: 'Monthly' },
      { value: 'Quarterly', label: 'Quarterly' },
      { value: 'Semi-Annual', label: 'Semi-Annual' },
      { value: 'OneTime', label: 'OneTime' },
    ];



  validatePrincipalAge(){
    this.ageValidationMsg="";
    let age=this.dateNow - (new Date(this.assuredDetailsMemberAssociate.value.birthdate).getFullYear());

    if(this.productInfo.age_from&&this.productInfo.age_from){
      if(age<this.productInfo.age_from||age>this.productInfo.age_to ){
        this.ageValidationMsg= "Principal age must be "+parseFloat(this.productInfo.age_from).toFixed(0)+" to "+ parseFloat(this.productInfo.age_to).toFixed(0);
        return false;
      }
      return true;
    }
    return true;
  }




  ngOnInit() {

    this.getProductInfo();


    this.furl=this.url.split('/')[1];
    this.items = [
      {label: 'Assured Details'},
      {label: 'Option Details'},
      {label: 'Bounderies Details'},
      {label: 'Beneficiary'},
      {label: 'Payment'}
    ];

    this.assuredDetailsMemberAssociate = new FormGroup({
      // assured: new FormControl('memberAssociateMember', [Validators.required]),
      lastname: new FormControl('', Validators.required),
      firstname: new FormControl('', Validators.required),
      middlename: new FormControl('', Validators.required),
      suffix: new FormControl(''),
      gender: new FormControl('', Validators.required),
      contact: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(11)] ),
      birthdate: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      address: new FormControl('', Validators.required),
      occupation: new FormControl('', Validators.required),
      zipCode: new FormControl('', Validators.required),
      branch: new FormControl(''),
      cid: new FormControl(''),
      coop_no:new FormControl(''),

    });
    this.assuredDetailsCorporate = new FormGroup({
      assuredName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      address: new FormControl('', [Validators.required]),
      contact: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(11)] ),
      zipCode: new FormControl('', [Validators.required]),
      branch: new FormControl('', [Validators.required]),
      coop_no:new FormControl(''),


    });
    this.benefitForm = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      middlename: new FormControl('', Validators.required),
      birthdate: new FormControl('', Validators.required),
      relationship: new FormControl('', Validators.required),
    });
    this.HDF=new FormGroup({
      'ans1': new FormControl(0, Validators.required),
      'ans2': new FormControl(1, Validators.required),
      'ans3': new FormControl(0, Validators.required),
      'ans4': new FormControl(0, Validators.required),
      'ans5': new FormControl(0, Validators.required),
      'ans6': new FormControl(0, Validators.required),
      'ans7': new FormControl('', Validators.required),
      'ans1YesAnser': new FormControl(''),
      'ans3YesAnse': new FormControl(''),
      'ans6YesAnser': new FormControl('')
    });

    this.occupancyDetails = new FormGroup({
      residence: new FormControl('HOME OWNER', Validators.required),
      bounderiesFront: new FormControl('', Validators.required),
      bounderiesLeft: new FormControl('', Validators.required),
      bounderiesRight: new FormControl('', Validators.required),
      bounderiesRear: new FormControl('', Validators.required),
    });    

    
    // tagum cid
    this.assuredDetailsMemberAssociate.get("cid").valueChanges
    .pipe(debounceTime(3000))
    .subscribe(res => {
      if(res){
        const Http = new XMLHttpRequest();
        const url= environment.tagumAPI+'/get-profile/'+res;
        Http.open("GET", url);
        Http.setRequestHeader( 'Content-Type', 'application/json');
        Http.setRequestHeader("Authorization", `Bearer ${environment.token_tagum}`);
        Http.send();

        Http.onreadystatechange = (e)=>{
          this.benefeciary =[];
          let assured=JSON.parse(Http.response);

          let pipe = new DatePipe('en-US'); // Use your own locale

          if(Http.status==200){
            this.assuredDetailsMemberAssociate
              .patchValue({
                lastname: assured.Message.appData.lastname,
                firstname: assured.Message.appData.firstname,
                middlename: assured.Message.appData.middlename,
                suffix: assured.Message.appData.suffix,
                gender: assured.Message.appData.gender.toLowerCase(),
                contact: assured.Message.appData.contactno,
                email:assured.Message.appData.email,
                address: assured.Message.appData.address,
                zipCode: assured.Message.appData.zipcode,
                birthdate: pipe.transform(assured.Message.appData.birthday, 'yyyy-MM-dd'),
                occupation: assured.Message.appData.occupation,
            });

            assured.Message.appData.dependents.forEach(row => {
              this.benefeciary.push({
                lastname:row.deplname,
                firstname:row.depfname,
                middlename:row.depmname,
                birthdate:pipe.transform(row.depbday, 'yyyy-MM-dd'),
                relationship:row.relationship,
              });
            });
            
          }
          else{
            this.assuredDetailsMemberAssociate.reset();
            Swal.fire(
              'Oppss',
              assured.Message +' ('+ res +')',
              'error'
            );
          }  
        }
      }
    });



    this.assuredDetailsCorporate.get("coop_no").valueChanges
    .pipe(debounceTime(2000))
    .subscribe(res => {
      if(res && typeof(res)==='string'){
       this.search(res)
      }
    });


    this.assuredDetailsMemberAssociate.get("coop_no").valueChanges
    .pipe(debounceTime(2000))
    .subscribe(res => {
      if(res && typeof(res)==='string'){
      this.search(res)
      }
    });
  }



  closeSearchList(){
    this.searchListShow = false;
  }

  searchItem(searhItem){

    this.searchListShow = false;
    this.searchitems = searhItem;
    this.assuredDetailsMemberAssociate.patchValue({coop_no: searhItem.CoopNum});
    this.assuredDetailsCorporate.patchValue({coop_no: searhItem.CoopNum});

    this.assuredDetailsCorporate.patchValue({assuredName: searhItem.Name});

  }
  search(res){
    this.isLoading = true;

    let data = {
      search:res
    }
        
    this.cacProduct.searchGroupClient(data).subscribe((res:any)=>{
      this.isLoading = false;
     
      this.searchListShow = true;  
      this.searchResult = res;  
    },error=>{
      this.isLoading = false;

      Swal.fire(
        'Oppss',
        'Something went wrong.',
        'error'
      );
    });
  }



  async viewPolicyDetails(){
    this.quoteData = await this.quotation.view_quotation(this.quoteId).toPromise();

    this.new_business = this.quoteData[0].statusid==7? true: false;

    this.prev_policy_no = this.quoteData[0].policyno;
    
    this.memberType=this.quoteData[0].assured.assuredType;

    this.selectedType =this.quoteData[0].option.prodoptid; 

    let ef = new Date();
    let effdate = moment(ef).format('YYYY-MM-DD');

    this.effectivity_date = effdate;
    

    if(this.quoteData[0].assured.assuredType=="memberAssociateMember"){
      this.assuredDetailsMemberAssociate.patchValue({
        lastname  :this.quoteData[0].assured.lastName,
        firstname :this.quoteData[0].assured.firstName,
        middlename:this.quoteData[0].assured.middleName,
        suffix    :this.quoteData[0].assured.suffix,
        gender    :this.quoteData[0].gender,
        contact   :this.quoteData[0].contactNo,
        birthdate :this.quoteData[0].birthday,
        email     :this.quoteData[0].emailAddress,
        address   :this.quoteData[0].address,
        occupation:this.quoteData[0].occupation,
        zipCode   :this.quoteData[0].zipCode,
        coop_no   :+this.quoteData[0].coopNo,     

      });
    }
    if(this.quoteData[0].assured.assuredType=="corporate"||this.quoteData[0].assured.assuredType=="cooperative"){
      this.assuredDetailsCorporate.patchValue({
        assuredName :this.quoteData[0].assured.assuredName,
        email       :this.quoteData[0].emailAddress,
        address     :this.quoteData[0].address,
        contact     :this.quoteData[0].contactNo,
        zipCode     :this.quoteData[0].zipCode,
        coop_no     :+this.quoteData[0].coopNo,  
      });
    }

    this.quoteData[0].beneficiary.forEach(row => {
      this.benefeciary.push({
        firstname: row.firstName,
        lastname: row.lastName,
        middlename: row.middleName,
        birthdate: row.birthday,
        relationship: row.relationship,
      });
    });
  }


  nextPage(){
    if(this.validatePrincipalAge()==true){
      this.dialogIndex++;
    }
  }

  previousPage(){
    this.dialogIndex--;
  }

  addBenefit(){
    this.benefeciary.push(this.benefitForm.value);
  }


  getProductInfo(){
    let data={
      variantId: this.productId,
      login_id: this.quoteId>0? this.login_id:this.User.iloginId,
    }
    this.IssuePolicy.getProductInfo(data).subscribe((res:any)=>{

      if(this.quoteId>0){
        this.viewPolicyDetails();
      }
      
      this.productInfo=res;
      if(this.productInfo.canSave=="0"){
        Swal.fire(
          'Alert',
          'This product '+this.productInfo.varlongname+' is currently disabled.',
          'warning'
        );
      }
    },error=>{
      Swal.fire(
        'Oppss',
        'Something went wrong.',
        'error'
      );
    });
  }


  
  showDetails(){
    this.show = true;

    this.details=this.productInfo.prod[0].option.find(x =>x.id==this.selectedType);
    if(this.productInfo.variantid=='6'&&this.validatePrincipalAge()==true ){
      this.basicModal4.show();
    }
  }


  compute(){
    let o_pay_total:any=0;
    this.productInfo.payment_list_rates.forEach(element => {
       o_pay_total += +(element.paymentListNo==5||element.paymentListNo==2? (this.details.grossprem*this.unit) * (element.rate/100) :element.rate);
    });
    this.netpremium = o_pay_total + (+this.details.grossprem*this.unit);

    // this.totalCalCommission = (this.details.grossprem*this.unit) * (this.productInfo.new_comm.reduce((prev,next)=>+prev+ +next.comm_rate_max,0)/100);
    this.totalCalCommission = (this.details.grossprem*this.unit) * (+this.productInfo.prod[0].commission[0].comm_rate_max/100);

  }


  netCommision(){
    if(this.waivedCommission > this.totalCalCommission ){
      this.waivedCommission=0;
      Swal.fire(
        'Note!',
        'waived commission max is ' +this.totalCalCommission,
        'warning'
      );
    }
    else{
      this.basicModal3.hide();
    } 
  }
  


  async issuePolicy(isQuote){
    this.clicked=true;
    this.spinner.show();

    let gross_prem:any = this.details.grossprem*this.unit;

    let hdf={
      'ans1Label': this.HDF.value.ans1=='1'?'Yes':'No',
      'ans2Label': this.HDF.value.ans2=='1'?'Yes':'No',
      'ans3Label': this.HDF.value.ans3=='1'?'Yes':'No',
      'ans4Label': this.HDF.value.ans4=='1'?'Yes':'No',
      'ans5Label': this.HDF.value.ans5=='1'?'Yes':'No',
      'ans6Label': this.HDF.value.ans6=='1'?'Yes':'No'
    }


    let ef = new Date(this.effectivity_date)
    let oneyear = ef.setDate(ef.getDate()+365);
    let oneyear_date = moment(oneyear).format('YYYY-MM-DD');


    this.formData.set('assured[assuredName]', this.memberType == "memberAssociateMember"? this.assuredDetailsMemberAssociate.value.firstname +' '+ this.assuredDetailsMemberAssociate.value.middlename +' '+ this.assuredDetailsMemberAssociate.value.lastname: this.assuredDetailsCorporate.value.assuredName);
    this.formData.set('assured[createdBy]', this.quoteId>0? this.quoteData[0].agentNo: this.User.iloginId);
    this.formData.set('assured[firstName]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.firstname:"");
    this.formData.set('assured[lastName]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.lastname:"");
    this.formData.set('assured[middleName]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.middlename:"");
    this.formData.set('assured[suffix]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.suffix:"");
    this.formData.set('assured[address]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.address:this.assuredDetailsCorporate.value.address);
    this.formData.set('assured[contact]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.contact:this.assuredDetailsCorporate.value.contact);
    this.formData.set('assured[email]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.email:this.assuredDetailsCorporate.value.email);
    this.formData.set('assured[assuredType]', this.memberType);
    this.formData.set('assured[gender]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.gender:"");
    this.formData.set('assured[birthdate]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.birthdate:"");
    this.formData.set('assured[occupation]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.occupation:"");
    this.formData.set('assured[coopNo]', this.quoteId>0? this.quoteData[0].coopNo: (this.assuredDetailsCorporate.value.coop_no==null? this.assuredDetailsCorporate.value.coop_no: this.User.coop_maincode));
    this.formData.set('assured[zipCode]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.zipCode:this.assuredDetailsCorporate.value.zipCode);
    this.formData.set('assured[posting_type]', this.posting_type);
    this.formData.set('assured[branch]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.branch: this.assuredDetailsCorporate.value.branch);
    this.formData.set('assured[cid]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.cid:"")
    this.formData.set('assured[issuedFrom]', 'Web');
    this.formData.set('assured[isQuote]', isQuote);
    this.formData.set('assured[new_business]', this.new_business==true?'2' :'1');
    this.formData.set('assured[prev_policy_no]', this.prev_policy_no);
    this.formData.set('assured[effectivityDate]', this.effectivity_date);
    this.formData.set('assured[expiryDate]', oneyear_date);
    this.formData.set('assured[id]', this.quoteId>0 && this.new_business==false ? this.quoteData[0].assuredListNo:'');
    this.formData.set('assured[quotationno]', this.quoteId>0 && this.new_business==false ?this.quoteData[0].id:'');
    this.formData.set('assured[referer]', this.referer);
    this.formData.set('assured[variantId]', this.productInfo.variantid);
    this.formData.set('assured[policy]', this.productInfo.varshortname);
    this.formData.set('assured[remarks]', '');
    this.formData.set('policyHeader[policy]', this.productInfo.varshortname);
    this.formData.set('policyHeader[variantid]', this.productInfo.variantid);
    this.formData.set('policyHeader[productNo]', this.details.productId);
    this.formData.set('policyHeader[units]', this.unit);
    this.formData.set('option[prodoptid]', this.details.id);
    this.formData.set('beneficiary', JSON.stringify(this.benefeciary));
    this.formData.set('paymentHeader[netPremium]', this.netpremium);
    this.formData.set('paymentHeader[waiveComm]', this.waivedCommission);
    this.formData.set('paymentHeader[grossPremium]', gross_prem);
    this.formData.set('paymentOther', JSON.stringify(this.productInfo.payment_list_rates));
    this.formData.set('hdf', JSON.stringify(this.productInfo.classification==1? Object.assign(this.HDF.value, hdf):''));
    this.formData.set('building[residence]', this.occupancyDetails.value.residence);
    this.formData.set('building[bounderiesFront]', this.occupancyDetails.value.bounderiesFront);
    this.formData.set('building[bounderiesLeft]', this.occupancyDetails.value.bounderiesLeft);
    this.formData.set('building[bounderiesRight]', this.occupancyDetails.value.bounderiesRight);
    this.formData.set('building[bounderiesRear]', this.occupancyDetails.value.bounderiesRear);



    this.IssuePolicy.issuePolicy(this.formData).subscribe((res:any)=>{
      this.spinner.hide();

      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );
    
      if(res.data.policyno){
        this.router.navigate(['/'+this.furl+'/policy-issued']);
      } else{
        this.router.navigate(['/'+this.furl+'/issued-policy-quotation']);
      }

    },error=>{
      this.clicked=false;
      this.spinner.hide();

      let err=error.status == 0?{msg: 'Something went wrong.'}: error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Error',
        msg,
        'error'
      );
    });
  }




}
