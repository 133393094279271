import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class IssuePolicyService {
  url = environment.url; 


  constructor(private http: HttpClient) { }

  vehicleClassification(){
    return this.http.get(this.url + '/policyIssue/vehicleClassification');
  }

  vehicleList(id){
    return this.http.get(this.url + '/policyIssue/vehicleList/' +id);
  }

  // vehicleList1(id){
  //   return this.http.get(this.url + '/policyIssue/vehicleList/' +id).toPromise();
  // }

  benefitList(){
    return this.http.get(this.url + '/policyIssue/benefitList');
  }

  benefitRate(ids){
    return this.http.post(this.url + '/policyIssue/benefitRate', ids);
  }

  additionalFees(){
    return this.http.get(this.url + '/policyIssue/additionalFees');
  }

  get_points(data){
    return this.http.post(this.url + '/get_points', data  );
  }

  // getCommissionRate(){
  //   return this.http.get(this.url + '/policyIssue/getCommissionRate' );
  // }

  issuePolicy(data){
    return this.http.post(this.url + '/policyIssue/issuePolicy', data );
  }
  
  activate(data){
    return this.http.post(this.url + '/policyIssue/activate' , data );
  }

  canSave(id){
    return this.http.get(this.url + '/policyIssue/canSave/'+ id ).toPromise() ;
  }

  issuedPolicy(agentNo,data){
    return this.http.post(this.url + '/policyIssue/issuedPolicy/'+ agentNo, data );
  }

  filterByPolicy(agentNo){
    return this.http.get(this.url + '/policyIssue/filterByPolicy/' + agentNo );
  }

  // saveCompreQuote(files){
  //   return this.http.post(this.url + '/policyIssue/saveCompreQuote',files );

  // }

  riskClassification(){
    return this.http.get(this.url + '/policyIssue/riskClassification');
  }

  tarriffInterpretation(id){
    return this.http.get(this.url + '/policyIssue/vehicleList/' +id);
  }

  zone(){
    return this.http.get(this.url + '/policyIssue/zone');
  }

  
  classClassification(){
    return this.http.get(this.url + '/policyIssue/classClassification');

  }

  tarriffRate(){
    return this.http.get(this.url + '/policyIssue/tarriffRate');

  }


  benefitRatefire(){
    return this.http.get(this.url + '/policyIssue/benefitRatefire');

  }

  RSMDoption(){
    return this.http.get(this.url + '/policyIssue/RSMDoption');
  }


  paymentListRateStandardFire(){
    return this.http.get(this.url + '/policyIssue/paymentListRateStandardFire');
  }

  // saveStandardFireQuote(data){
  //     return this.http.post(this.url + '/policyIssue/saveStandardFireQuote' ,data );

  // }


  issuedPolicyQuote(agentNo, branch){
    return this.http.post(this.url + '/policyIssue/issuedPolicyQuotation/'+ agentNo, branch );
  }


  cancelQuote(quoteId){
    return this.http.get(this.url + '/policyIssue/cancelQuote?quotId='+quoteId+'&remarks="Canceled by user"' );
  }


  sendEmailQuotation(data){
    return this.http.post(this.url + '/emailQuotationPolicy', data );
  }

  quotationDetails(quoteId){
    return this.http.get(this.url + '/policyIssue/quotationDetails/'+quoteId);
  }


  selectBranch(quoteId){
    return this.http.get(this.url + '/policyIssue/selectBranch/'+quoteId);

  }


  applyforPolicy(data){
    return this.http.post(this.url + '/policyIssue/applyforPolicy',data)
  }

  updateStandardFireQuote(data,quoteId){
    return this.http.post(this.url + '/policyIssue/updateStandardFireQuote/'+quoteId ,data  );
  }

  updateComprehensiveQuote(data,quoteId){
    return this.http.post(this.url + '/policyIssue/updateComprehensiveQuote/'+quoteId ,data  );
  }

  getPoints(loginId){
    return this.http.get(this.url + '/getPoints/'+loginId );
  }



  // downloadPolicy(data){
  //   return this.http.post('https://api.climbs.coop/emailIssuanceForPortal/email', data );
  //   // return this.http.post('http://localhost:80/cms-be/emailIssuanceForPortal/email', data );

  // }



  getProductInfo(data){
    return this.http.post(this.url + '/getProductInfo',data );
  }


  print_quote_wo_saving(data){
    const httpOptions = {
      responseType: ('blob' as 'json')
    };
    
    return this.http.post(this.url + '/print_quote_wo_saving', data, httpOptions);

  }


  getProductlist(){
    return this.http.get(this.url + '/product/getVariants');
  }


  masterPolicy(data){
    return this.http.post(this.url + '/masterPolicy', data);
  }

  masterPolicyExport(data){

    const httpOptions = {
      responseType: ('blob' as 'json')
    };
    return this.http.post(this.url + '/masterPolicyExport', data,httpOptions);
  }

}
