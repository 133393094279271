import { Component, OnInit, ViewChild } from '@angular/core';
import { OrIssuanceService } from '../../../services-http/orIssuance/or-issuance.service';
import { ToastService  } from 'ng-uikit-pro-standard';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { ModalDirective  } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { CommissionPremiumSetupService } from '../../../services-http/commission-premium-setup/commission-premium-setup.service';

@Component({
  selector: 'app-or-issuance',
  templateUrl: './or-issuance.component.html',
  styleUrls: ['./or-issuance.component.scss']
})

export class OrIssuanceComponent implements OnInit {
  @ViewChild('frame') frame: ModalDirective;

  searchListShow:boolean = false;
  elements:any =[];
  data:any={data:[]};
  posting_type:number=2;
  search:any='';
  headElements = ['','Name/ Coop Name','Insurance Type' , 'Particular','Posting Type','Date Paid','PR/Bill No.','Amount','Management Fee','Remove'];
  or_no:any;
  date:any;
  options:any = { opacity: 0 };
  area:any = '';
  orIssuanceAreas:any =[];
  classification:any =''
  l = new Date();
  dateto = this.l.toISOString().substring(0,10);
  f = new Date("2013-01-01");
  datefrom = this.f.toISOString().substring(0,10);
  page:number=1;
  isLoading:boolean = false;
  list_of_checked_policy:any=[];
  searchSubject = new Subject<string>();
  allProduct:any = [];
  prod:any = '';
  user:any = JSON.parse(localStorage.getItem('currentUser'));
  totalGros:any=0;
  commission:number = 0.00;


  constructor(
    private or:OrIssuanceService, 
    private toastrService: ToastService , 
    private productHis: ProductionHistoryService,
    private spinner: NgxSpinnerService,
    private com:CommissionPremiumSetupService
  ) { 

      this.searchSubject.pipe(
        debounceTime(1200)
      ).subscribe(searchValue => {
        this.display(1, false);
      });
  }


  onSearchChange(searchValue) {

    if(searchValue.replace(/\s/g, '')){
      this.searchSubject.next(searchValue);
    }
  }


  ngOnInit() {
    this.orIssuanceArea();

    this.productsListing();
  }



  management_fee(data){
    let sum=0;

    if(data.agent_type==3||data.agent_type==5){
      sum = data.cac_collection_fee;
    }

    else if(data.agent_type==6){
      sum = data.gam_collection_fee;
    }

    else if(data.agent_type==1||data.agent_type==2||data.agent_type==4){
      sum = data.agent_collection_fee;
    }
    return sum;
  }



  display(page, expor){
    this.isLoading = true;

    let data = {
      search: this.search,
      posting_type:this.posting_type,
      page:page,
      area:this.area,
      datefrom:this.datefrom,
      dateto:this.dateto,
      export: expor,
      classification: this.classification,
      product: this.prod
    }

    this.or.display(data).subscribe((res:any)=>{
      this.isLoading = false;
   
      if(!expor){
        this.data=[];
        this.data = res;
        this.searchListShow =true;
        return; 
      }

      window.open(res);
      
    },error=>{
      this.isLoading = false;
    });
  }




  saveOR(){
    let data = {
      date: this.date,
      or_no: this.or_no,
      posting_type: this.posting_type,
      data: this.list_of_checked_policy
    }

    this.or.saveOR(data).subscribe((res:any)=>{
      Swal.fire(
        'Success',
        res.msg,
        'success'
      );
  
      this.or_no='';
      this.date='';
      this.frame.hide();
      this.list_of_checked_policy=[];
      this.search="";
      this.commission=0;
      this.totalGros=0;

    },error=>{
      Swal.fire(
        'Error',
        'Something went wrong!',
        'error'
      );
    });
  }


  removePolicy(i){
    this.list_of_checked_policy.splice(i, 1);
    
    this.totalGros = this.list_of_checked_policy.reduce((sumAccessories, current) => +sumAccessories + +current.netpremium, 0.00);

    let cac   = this.list_of_checked_policy.filter(x=>x.agent_type==3||x.agent_type==5).reduce((sumAccessories, current) => +sumAccessories + +current.cac_collection_fee, 0.00);

    let gam   = this.list_of_checked_policy.filter(x=>x.agent_type==6).reduce((sumAccessories, current) => +sumAccessories + +current.gam_collection_fee, 0.00);

    let agent = this.list_of_checked_policy.filter(x=>x.agent_type==1||x.agent_type==2||x.agent_type==4).reduce((sumAccessories, current) => +sumAccessories + +current.agent_collection_fee, 0.00);

    this.commission = cac+gam+agent;
  }


  addPolicy(){

    let check = this.data.filter(x=>x.checked == true);

    check.forEach(row => {
      let exist=this.list_of_checked_policy.find(x=>x.keyid==row.keyid);

      if(!exist){
        this.list_of_checked_policy.push(row); 
      }
    });


    let cac   = this.list_of_checked_policy.filter(x=>x.agent_type==3||x.agent_type==5).reduce((sumAccessories, current) => +sumAccessories + +current.cac_collection_fee, 0.00);

    let gam   = this.list_of_checked_policy.filter(x=>x.agent_type==6).reduce((sumAccessories, current) => +sumAccessories + +current.gam_collection_fee, 0.00);

    let agent = this.list_of_checked_policy.filter(x=>x.agent_type==1||x.agent_type==2||x.agent_type==4).reduce((sumAccessories, current) => +sumAccessories + +current.agent_collection_fee, 0.00);

    this.commission = cac+gam+agent;

    this.totalGros = this.list_of_checked_policy.reduce((sumAccessories, current) => +sumAccessories + +current.netpremium, 0.00);
    close();
  }



  print_paid_bill(bill_id){
    this.spinner.show();
    
    this.productHis.print_paid_bill(bill_id).subscribe((res:any) => {
      this.spinner.hide();
      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "paid-bill-statement.pdf";
      link.click();

    },error=>{
      console.log(error)
    });
  }



  orIssuanceArea(){
    this.or.orIssuanceArea().subscribe(res=>{
      this.orIssuanceAreas= res;
    },error=>{
      console.log(error)
    });
  }


  // pay_all(e, i){
    
  //   this.data.data[i].policies.forEach(row => {
  //     // row['checked']= e.checked==true? true:false;

  //     if(e.checked&&row.or_no==''){
  //       row['checked']=true;
  //     }
  //     else if(!e.checked&&row.or_no==''){
  //       row['checked']=false;
  //     }
      
  //   });

  // }


  pay_all(e){
    this.data.forEach(row => {
      row['checked'] = e.checked==true? true:false;
    });
  }


  close(){
    this.searchListShow=false;
    this.search="";
  }



  productsListing(){
    let data ={
      code: '',
      type: this.user.usertype==1 || this.user.usertype==8 || this.user.usertype==12?  [2,3,4,5,6]: [this.user.usertype]
    }

    this.com.displayCom(data).subscribe(res=>{
      this.allProduct = res;
    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }


  

}
