import { Component, OnInit } from '@angular/core';
import { BillingService } from '../../../services-http/billing/billing.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin-transaction-history',
  templateUrl: './admin-transaction-history.component.html',
  styleUrls: ['./admin-transaction-history.component.scss']
})


export class AdminTransactionHistoryComponent implements OnInit {
  mydate = new Date();
  date = this.mydate.toISOString().substring(0,10);
  
  transactions:any ={data :[]};
  transtype:any='';
  users:any=[];
  coops:any=[];
  user:any='';
  coop:any='';
  from:any='2023-01-01';
  to:any=this.date;
  payment_type:any ='';

 

  constructor(private bill: BillingService,   private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.adminTransactionHistory(1);

    this.adminTransactionHistoryUsers();

    this.adminTransactionHistoryCoop();
  }
 
  adminTransactionHistoryCoop(){
    this.bill.adminTransactionHistoryCoop().subscribe(res=>{

      this.coops = res;
    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }

  adminTransactionHistoryUsers(){
    this.bill.adminTransactionHistoryUsers().subscribe(res=>{

      this.users = res;
    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }


  adminTransactionHistory(page){
    let data ={
      login_id : this.user,
      trans_type: this.transtype,
      page: page,
      export:false,
      coop:this.coop,
      from:this.from,
      to:this.to,
      payment_type:this.payment_type
    }

    this.spinner.show();

    this.bill.adminTransactionHistory(data).subscribe(res=>{
      this.transactions = res;
    this.spinner.hide();


    },error=>{
    this.spinner.hide();

      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }


  adminTransactionHistoryExport(page){
    let data ={
      login_id : this.user,
      trans_type: this.transtype,
      page: page,
      export:true,
      coop:this.coop,
      from:this.from,
      to:this.to,
      payment_type:this.payment_type
    }
    this.spinner.show();

    this.bill.adminTransactionHistory(data).subscribe((res:any)=>{
      window.open(res)

    this.spinner.hide();
  },error=>{
    this.spinner.hide();

      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }

}
