import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services-http/user/user.service';
import { Router,ActivatedRoute } from '@angular/router'
import Swal from 'sweetalert2'
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-admin-user-management',
  templateUrl: './admin-user-management.component.html',
  styleUrls: ['./admin-user-management.component.scss']
})
export class AdminUserManagementComponent implements OnInit {

  users:any = [];
  headElements = [' ','ID','User type', 'Code','Name', 'Coop', 'Email','Contact no','Password','Date created','Status', 'Action'];
  searchUserText:any = '';
  currentLoginId;
  currentDate = new Date();
  todate; 
  loading:boolean = true;
  timeout:boolean = false;
  url:any ='';
  view_pass:any='';
  user_detail:any;

  constructor(
    private userService: UserService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { 
    this.route.parent.url.subscribe((urlPath) => {
      this.url = urlPath[urlPath.length - 1].path;
    });
  }

  ngOnInit() {

    this.loadUsers(1);
    let today = this.currentDate.getDate();
    let month = this.currentDate.getMonth() + 1;
    let year = this.currentDate.getFullYear();
    this.todate = year + '-' + month + '-' + today;
  }

 
  exports(pageno) {
    this.userService.loadUsers(pageno, this.searchUserText,1).subscribe((res:any) => {

      window.open(res);
    },error=>{
      this.spinner.hide();
    });
  }
 
 
  loadUsers(pageno) {
    this.userService.loadUsers(pageno, this.searchUserText,0).subscribe((res:any) => {

      this.users=res;
    },error=>{
      this.spinner.hide();
    });
  }

  // gotoAddUser() {
  //   this.router.navigateByUrl('/'+this.url+'/users/add');
  // }

  // gotoViewUser(id) {
  //   this.router.navigateByUrl('/'+this.url+'/users/' + id + '/view')
  // }

  gotoUpdateUser(id) {
    this.router.navigateByUrl('/'+this.url+'/users/' + id + '/update')
  }

  showDeleteUserPrompt(userId) {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, deactivate it!'
    }).then((result) => {
      if (result.value) {
    
        let data = {
          status: 2,
          date_updated: this.todate,
          updated_by: this.currentLoginId['userId']
        }

        this.userService.updateUser(userId, data).subscribe(res => {
          this.loadUsers(1);
          Swal.fire(
            'Deactivated!',
            'This user has been deleted.',
            'success'
          );
        });
      }
    });
  }


  refresh(){
    this.loadUsers(1);
  }


  redirect(user){
    let coopno = user.login_typeid==5?user.agent_details.cacgam_code:user.pkid_value;

    this.router.navigateByUrl('/'+this.url+'/user-commission/'+coopno);
    // window.open('/#/'+this.url+'/user-commission/'+coopno+'/type/'+id);
  }

  

}
