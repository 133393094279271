import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router,ActivatedRoute } from '@angular/router';
import { MenuItem, SelectItem } from 'primeng/api';
import { CacProductService } from '../../../services-http/cac-product/cac-product.service';
import { debounceTime } from "rxjs/operators";
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { validateEffectivityDate } from '../../../customValidation/setEffectivityDate.validation';
import { QuotationService } from '../../../services-http/quotation/quotation.service';
import {environment} from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-standard-fire',
  templateUrl: './standard-fire.component.html',
  styleUrls: ['./standard-fire.component.scss']
})
export class StandardFireComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('basicModal3') basicModal3: ModalDirective;

  allContent:any = [];
  items: MenuItem[];
  types: SelectItem[];
  dialogIndex:number = 0;
  show:boolean =true;
  memberType:any = "memberAssociateMember";
  formData = new FormData();
  tarriffClassification:any = [];
  User = JSON.parse(localStorage.getItem('currentUser'));
  waivedCommission:any = 0;
  total_calc_commission:any = 0;
  RSMDchoose:any = 0;
  basicPremium:any = 0;
  netPremium:any =0;
  quoteId:any;
  url=this.router.url;
  furl;
  productInfo:any={points:{credit:0,regular:0}};
  totalCoverage:any=0;
  RSMDoption:any=[];
  posting_type:any=0;
  attachment:any=[];
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDay();
  now1 = this.now.setDate(this.now.getDate() - 45);
  minDate:any = moment(this.now1).format('YYYY-MM-DD');
  min=0;
  max=0;
  quoteData:any;
  prev_policy_no:any ='';
  new_business:boolean = false;
  cus_content:boolean = false;
  searchListShow:boolean = false;
  searchitems:any={};
  searchResult:any = [];
  isLoading:boolean=false;
  referer:any = '';



  content = new FormGroup({
    amount: new FormControl('0',[Validators.required, Validators.pattern(/^[0-9.]*$/)]),
    bamount: new FormControl(0,[Validators.pattern(/^[0-9.]*$/)]),
    name: new FormControl('',Validators.required),
  });

  assuredDetailsMemberAssociate = new FormGroup({
    lastname: new FormControl('', Validators.required),
    firstname: new FormControl('', Validators.required),
    middlename: new FormControl('', Validators.required),
    suffix: new FormControl(''),
    gender: new FormControl('', Validators.required),
    contact: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(12)] ),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    address: new FormControl('', Validators.required),
    zipCode:  new FormControl('', Validators.required),
    cid: new FormControl(''),
    branch: new FormControl(''),
    new_business: new FormControl(''), 
    prev_policy_no: new FormControl(''),
    remarks :new FormControl(''),
    coop_no: new FormControl(''),
  });


  assuredDetailsCorporate = new FormGroup({
    assuredName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    address: new FormControl('', Validators.required),
    contact: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(12)] ),
    zipCode: new FormControl('', Validators.required),
    new_business: new FormControl(''), 
    prev_policy_no: new FormControl(''),
    branch: new FormControl(''),
    cid: new FormControl(''),
    coop_no: new FormControl(''),
  });


  buildingDetails = new FormGroup({
    buildingLocation: new FormControl('', Validators.required),
    BuildingDescription: new FormControl('', Validators.required),
    riskClassification: new FormControl('', Validators.required),
    tarriffInterpret: new FormControl('', Validators.required),
    zoneArea: new FormControl('', Validators.required),
    classClassification: new FormControl('', Validators.required),
    tariffCode: new FormControl('', Validators.required),
    tariffRate: new FormControl('', Validators.required),
    tariffName: new FormControl('', Validators.required),
    buildingCoverage: new FormControl('', [Validators.required, Validators.pattern(/^[0-9.,]*$/)] ),
    renew: new FormControl(0, [Validators.required]), 
    giventariffRate: new FormControl(''),
    effectivity_date: new FormControl(''),
    mortgaged: new FormControl("no", Validators.required),
    mortgagee: new FormControl('')
  });

  occupancyDetails = new FormGroup({
    buildingInclusion: new FormControl(1, Validators.required),
    bounderiesFront: new FormControl('', Validators.required),
    bounderiesLeft: new FormControl('', Validators.required),
    bounderiesRight: new FormControl('', Validators.required),
    bounderiesRear: new FormControl('', Validators.required),
  });

  login_id:any;

  constructor(
    private IssuePolicy: IssuePolicyService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private cacProduct:CacProductService,
    private quotation: QuotationService,
    private http: HttpClient
  ) {
    this.route.params.subscribe(val => {

      this.quoteId=val.quoteId;
      this.login_id=val.login_id;
    });
  }

  nextPage(){
    this.dialogIndex++;
  }

  previousPage(){
    this.dialogIndex--;
  }

  ngOnInit() {
    this.furl=this.url.split('/')[1];

    this.items = [
      {label: 'Assured'},
      {label: 'Building'},
      {label: 'Occupancy'},
      {label: 'Content'},
      {label: 'Benefit'},
      {label: 'Warranties & Clauses'},
      {label: 'Attachment'},
      {label: 'Computation'},
    ];

    this.getProductInfo();

    let ef = new Date();
    let effdate = moment(ef).format('YYYY-MM-DD');

    this.buildingDetails.patchValue({effectivity_date: effdate});

    this.buildingDetails.get("renew").valueChanges.subscribe(x => {
      if(x){
        this.buildingDetails.get("effectivity_date").setValidators([Validators.required,validateEffectivityDate.valEffDate]);
      }else{
        this.buildingDetails.patchValue({effectivity_date: effdate});
        this.buildingDetails.get("effectivity_date").clearValidators();
        this.buildingDetails.get('effectivity_date').setErrors(null); 
      }
      this.buildingDetails.get("effectivity_date").updateValueAndValidity();
    });  


    // check tarriff not more than 50%
    this.buildingDetails.get("giventariffRate").valueChanges.subscribe(x => {
 
      this.checkTarrif();
    });



    // tagum cid
    this.assuredDetailsMemberAssociate.get("cid").valueChanges
      .pipe(debounceTime(3000))
      .subscribe(res => {
        if(res){
          const Http = new XMLHttpRequest();
          const url= environment.tagumAPI+'/get-profile/'+res;
          Http.open("GET", url);
          Http.setRequestHeader( 'Content-Type', 'application/json');
          Http.setRequestHeader("Authorization", `Bearer ${environment.token_tagum}`);
          Http.send();
  
          Http.onreadystatechange = (e)=>{
            let assured=JSON.parse(Http.response);

            if(Http.status==200){
              this.assuredDetailsMemberAssociate
                .patchValue({
                  lastname: assured.Message.appData.lastname,
                  firstname: assured.Message.appData.firstname,
                  middlename: assured.Message.appData.middlename,
                  suffix: assured.Message.appData.suffix,
                  gender: assured.Message.appData.gender.toLowerCase(),
                  contact: assured.Message.appData.contactno,
                  email:assured.Message.appData.email,
                  address: assured.Message.appData.address,
                  zipCode: assured.Message.appData.zipcode
              });
            }
            else{
              this.assuredDetailsMemberAssociate.reset();
              Swal.fire(
                'Oppss',
                assured.Message +' ('+ res +')',
                'error'
              );
            }  
          }
        }
    });

    this.assuredDetailsMemberAssociate.get("coop_no").valueChanges
      .pipe(debounceTime(2000))
      .subscribe(res => {
        if(res && typeof(res)==='string'){
         this.search(res);
        }
    });

    this.assuredDetailsCorporate.get("coop_no").valueChanges
      .pipe(debounceTime(2000))
      .subscribe(res => {
        if(res && typeof(res)==='string'){
         this.search(res);
        }
    });

    
  }


  closeSearchList(){
    this.searchListShow = false;
  }

  searchItem(searhItem){

    this.searchListShow = false;
    this.searchitems = searhItem;
    this.assuredDetailsMemberAssociate.patchValue({coop_no: searhItem.CoopNum});
    
    this.assuredDetailsCorporate.patchValue({coop_no: searhItem.CoopNum});
    this.assuredDetailsCorporate.patchValue({assuredName: searhItem.Name});
  }



  search(res){
    this.isLoading = true;

    let data = {
      search:res
    }
        
    this.cacProduct.searchGroupClient(data).subscribe((res:any)=>{
      this.isLoading = false;
     
      this.searchListShow = true;  
      this.searchResult = res;  
    },error=>{
      this.isLoading = false;
      Swal.fire(
        'Oppss',
        'Something went wrong.',
        'error'
      );
    });
  }



  convertBlobToBase64(blob: Blob) {
    return Observable.create(observer => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
    
        this.attachment.push({
          'url':event.target.result,
          'file':event.target.result,
          'label':event.target.name
        });
      };

      reader.onerror = (event: any) => {

        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }


  async viewPolicyDetails(){
    this.quoteData = await this.quotation.view_quotation(this.quoteId).toPromise();
    this.new_business = this.quoteData[0].statusid==7? true: false;
    this.prev_policy_no = this.quoteData[0].policyno;
    this.memberType=this.quoteData[0].assured.assuredType;

    if(this.quoteData[0].assured.assuredType=="memberAssociateMember"){
      this.assuredDetailsMemberAssociate.patchValue({
        lastname  :this.quoteData[0].assured.lastName,
        firstname :this.quoteData[0].assured.firstName,
        middlename:this.quoteData[0].assured.middleName,
        suffix    :this.quoteData[0].assured.suffix,
        gender    :this.quoteData[0].gender,
        contact   :this.quoteData[0].contactNo,
        email     :this.quoteData[0].emailAddress,
        address   :this.quoteData[0].address,
        zipCode   :this.quoteData[0].zipCode,  
        coop_no   :+this.quoteData[0].coopNo     
   
      });
    }
    if(this.quoteData[0].assured.assuredType=="corporate"||this.quoteData[0].assured.assuredType=="cooperative"){
      this.assuredDetailsCorporate.patchValue({
        assuredName :this.quoteData[0].assured.assuredName,
        email       :this.quoteData[0].emailAddress,
        address     :this.quoteData[0].address,
        contact     :this.quoteData[0].contactNo,
        zipCode     :this.quoteData[0].zipCode,
        coop_no     :+this.quoteData[0].coopNo   
      });
    }

    this.getTariffInterpretation(this.quoteData[0].building.productNo);


    this.buildingDetails.patchValue({
      buildingLocation: this.quoteData[0].building.buildingLocation,
      BuildingDescription: this.quoteData[0].building.buildingDescription,
      tariffName: this.quoteData[0].building.tariffName,
      buildingCoverage: this.quoteData[0].building.buildingCoverage,
      // giventariffRate: this.quoteData[0].building.giventariffRate==0?'': this.quoteData[0].building.giventariffRate,
      mortgaged: this.quoteData[0].building.mortgaged,
      mortgagee: this.quoteData[0].building.mortgagee,
      riskClassification: this.quoteData[0].building.productNo,
      tarriffInterpret: this.quoteData[0].building.optionNo,
      zoneArea: this.quoteData[0].building.option2No,
      classClassification: this.quoteData[0].building.option3No,
      tariffCode: this.quoteData[0].building.tariffCode,
      tariffRate: this.quoteData[0].building.tariffRate,
      renew: 1,

    });


    this.occupancyDetails.patchValue({
      buildingInclusion: this.quoteData[0].building.buildingInclusion,
      bounderiesFront: this.quoteData[0].building.bounderiesFront,
      bounderiesLeft: this.quoteData[0].building.bounderiesLeft,
      bounderiesRight: this.quoteData[0].building.bounderiesRight,
      bounderiesRear: this.quoteData[0].building.bounderiesRear,
    });

    this.quoteData[0].content.forEach(row => {
      this.allContent.push({
        name: row.contentName,
        amount: row.coverage,
        bamount: row.burglary_coverage
      });
    });


    this.quoteData[0].upload.forEach(row => {

      this.attachment.push({
        'url': row.file_url,
        // 'url': this.downloadFile(row.file_url),
        'file': row.file_url,
        'label':row.filelabel
      });

     
    });

   

  }


  // downloadFile(url) {
  //   this.http.get(url, { responseType: 'blob' }).subscribe((blob) => {
  //     const file = new Blob([blob], { type: blob.type });
  //     console.log(file)
  //   });



  // }



  getProductInfo(){
    let data={
      variantId: 1,
      login_id : this.quoteId>0?this.login_id :this.User.iloginId,
      planNo   : 1
    }

    this.IssuePolicy.getProductInfo(data).subscribe((res:any)=>{

      if(this.quoteId>0){
        this.viewPolicyDetails();
      }

      this.productInfo=res;
      if(this.productInfo.canSave=="0"){
        Swal.fire(
          'Alert',
          'This product '+this.productInfo.varlongname+' is currently disabled.',
          'warning'
        );
      }
    },error=>{
      Swal.fire(
        'Oppss',
        'Something went wrong.',
        'error'
      );
    });
  }

  
  get lastname() { return this.assuredDetailsMemberAssociate.get('lastname'); }
  get firstname() { return this.assuredDetailsMemberAssociate.get('firstname'); }
  get middlename() { return this.assuredDetailsMemberAssociate.get('middlename'); }
  get suffix() { return this.assuredDetailsMemberAssociate.get('suffix'); }
  get gender() { return this.assuredDetailsMemberAssociate.get('gender'); }
  get contact() { return this.assuredDetailsMemberAssociate.get('contact'); }
  get email() { return this.assuredDetailsMemberAssociate.get('email'); }
  get address() { return this.assuredDetailsMemberAssociate.get('address'); }
  get zipCode() { return this.assuredDetailsMemberAssociate.get('zipCode'); }
  get cid() { return this.assuredDetailsMemberAssociate.get('cid'); }
  get branch() { return this.assuredDetailsMemberAssociate.get('branch'); }
  get assuredName() { return this.assuredDetailsCorporate.get('assuredName'); }
  get email1() { return this.assuredDetailsCorporate.get('email'); }
  get address1() { return this.assuredDetailsCorporate.get('address'); }
  get contact1() { return this.assuredDetailsCorporate.get('contact'); }
  get zipCode1() { return this.assuredDetailsCorporate.get('zipCode'); }
  get branch1() { return this.assuredDetailsCorporate.get('branch'); }
  get cid1() { return this.assuredDetailsCorporate.get('cid'); }
  get coop_no1() { return this.assuredDetailsCorporate.get('coop_no'); }



  get zoneArea() { return this.buildingDetails.get('zoneArea'); }
  get class() { return this.buildingDetails.get('classClassification'); }
  get tarriffInterpret() { return this.buildingDetails.get('tarriffInterpret'); }
  get tariffRates() { return this.buildingDetails.get('tariffRate'); }
  get giventariffRate() { return this.buildingDetails.get('giventariffRate'); }
  get renew() { return this.buildingDetails.get('renew'); }
  get effectivity_date() { return this.buildingDetails.get('effectivity_date'); }
  get mortgaged() { return this.buildingDetails.get('mortgaged'); }
  get mortgagee() { return this.buildingDetails.get('mortgagee'); }
  get remarks() { return this.assuredDetailsMemberAssociate.get('remarks'); }
  get coop_no() { return this.assuredDetailsMemberAssociate.get('coop_no'); }




  

  addContent(){
    this.show = true;
    this.allContent.push({name:this.content.value.name, bamount: this.content.value.bamount==null||this.content.value.bamount==''?0 :this.content.value.bamount , amount: this.content.value.amount})
  }


  removeContent(i){
    this.allContent.splice(i, 1);
  }
  

  onFileSelected(event, name) {

    if(this.attachment.find(x=>x.label==name)){
      Swal.fire(
        'Alert',
        'Already uploaded '+name+ ' ,remove it first if you want to replace.',
        'error'
      );
      return; 
    }

    // if(this.quoteId > 0 ){
    //   Swal.fire(
    //     'Alert',
    //     'No need to upload attachment',
    //     'error'
    //   );
    //   return; 
    // }

    var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		
		reader.onload = () => {
      this.attachment.push({
        'url':reader.result,
        'file':event.target.files[0],
        'label':name
      });
      
		}

  }

 

  getTariffInterpretation(val){
    this.buildingDetails.controls.tariffCode.setValue('');
    this.buildingDetails.controls.tariffRate.setValue('');

    this.tarriffClassification=this.productInfo.prod.find(x=>x.id==val);
  }


  //  check tarriff not more than 50%
  checkTarrif(){
    let g= this.buildingDetails.get("giventariffRate").value;
    if(!g){
      return
    }

    let fiftyPercent:any = this.tariffRates.value*.50; 

    this.min = (+this.buildingDetails.get('tariffRate').value - +fiftyPercent);
    this.max = (+this.buildingDetails.get('tariffRate').value + +fiftyPercent);
    
    if(g){
      this.buildingDetails.get("giventariffRate").setValidators([Validators.min(this.min),Validators.max(this.max), Validators.pattern(/^[0-9.,]*$/)]);
    }else{
      this.buildingDetails.get("giventariffRate").clearValidators();
    }
    this.buildingDetails.get("giventariffRate").updateValueAndValidity();
  }

 
  updateTariff() {
    let tarrif:any =this.tarriffClassification.option.find(x=>x.id==this.tarriffInterpret.value).tarrif.find(x=>x.optionNo2==this.zoneArea.value && x.optionNo3==this.class.value );

    if(tarrif){
      this.buildingDetails.controls.tariffCode.setValue(tarrif.printedId);
      this.buildingDetails.controls.tariffRate.setValue(tarrif.minRate);
      // this.buildingDetails.controls.giventariffRate.setValue(tarrif.minRate);
    }
    //  check tarriff not more than 50%
    this.checkTarrif();
  }

 

  benefitCalculation(){
    this.totalCoverage = +this.buildingDetails.value.buildingCoverage.replace(/,/g, '') + this.allContent.reduce((sumContent, current) => +sumContent + +current.amount.replace(/,/g, ''), 0);
    this.productInfo.benefit.forEach(element => {
      let premium = (( +this.giventariffRate.value==0? +this.tariffRates.value/100 :+this.giventariffRate.value/100)* +this.totalCoverage);

      element['checked']=element.id==1?true:false;
      element['rate']=element.id==1?( +this.giventariffRate.value==0? +this.tariffRates.value/100 :+this.giventariffRate.value/100):(+element.benefit_rate_values[0].minRate/100);
      element['coverage']=this.totalCoverage;
      element['premium']=element.id==1? (premium<400? 400: premium) : (+element.benefit_rate_values[0].minRate/100)* +this.totalCoverage;
      element['com_rate']=element['premium']* +this.tarriffClassification.commission.find(x=>x.benefit_name_id==element.id).comm_rate_max/100;
      element['benefitNo'] =element.id;
    });
  }



  getRSMD(){
    this.productInfo.benefit.forEach(element => {
      if(element.id==29){
        element['rate']=+this.RSMDchoose/100;
        element['coverage']=this.totalCoverage;
        element['premium']=(+this.RSMDchoose/100)* +this.totalCoverage;
        element['com_rate']=element['premium']* +(this.tarriffClassification.commission.find(x=>x.benefit_name_id==element.id).comm_rate_max/100);
      }
    });
  }

  

  compute(){

    Swal.fire({
      title: "<strong><u>NOTE!</u></strong>",
      html: `If the property is under insured this is subject for approval to head office. Click the get quotation instead.`,
    });

    let totalPrem:any=0;
    let other_payment:any=0;
    let total_commission:any=0;
    this.basicPremium = 0;
  
    // get TOtal Premium and commission
    this.productInfo.benefit.forEach(element => {
      if(element.checked){
        totalPrem+= +element.premium;
        total_commission+= +element.com_rate;
      }
    });

    this.basicPremium = +totalPrem + this.allContent.reduce((sumContent, current) => +sumContent + +current.bamount, 0) *.01 ;

    // get other payment total
    this.productInfo.payment_list_rates.forEach(element => {
      other_payment += element.paymentListNo==5||element.paymentListNo==2? +this.basicPremium * (+element.rate/100) :+element.rate;
    });

    this.total_calc_commission= +total_commission;
    this.netPremium = +other_payment+ +this.basicPremium;
  }


  netCommision(){
    if(this.waivedCommission > this.total_calc_commission ){
      this.waivedCommission=0;
      Swal.fire(
        'Note!',
        'waived commission max is ' +this.total_calc_commission,
        'warning'
      );
    }
    else{
      this.basicModal3.hide();
    } 
  }


  async save(isQuote){
    this.spinner.show();

    if(this.quoteId==0){
      
      this.attachment.forEach((row) => {
        this.formData.append('requirements[]', row['file'], row['label']);
      });
    }


    let ef = new Date(this.effectivity_date.value)
    let oneyear = ef.setDate(ef.getDate()+365);
    let oneyear_date = moment(oneyear).format('YYYY-MM-DD');


    this.formData.set('assured[assuredName]', this.memberType == "memberAssociateMember"? this.firstname.value +' '+ this.middlename.value +' '+ this.lastname.value: this.assuredName.value);
    this.formData.set('assured[createdBy]', this.quoteId>0? this.quoteData[0].agentNo: this.User.iloginId);
    this.formData.set('assured[firstName]', this.memberType == "memberAssociateMember"?this.firstname.value:"");
    this.formData.set('assured[lastName]', this.memberType == "memberAssociateMember"?this.lastname.value:"");
    this.formData.set('assured[middleName]', this.memberType == "memberAssociateMember"?this.middlename.value:"");
    this.formData.set('assured[suffix]', this.memberType == "memberAssociateMember"?this.suffix.value:"");
    this.formData.set('assured[address]', this.memberType == "memberAssociateMember"?this.address.value:this.address1.value);
    this.formData.set('assured[contact]', this.memberType == "memberAssociateMember"?this.contact.value:this.contact1.value);
    this.formData.set('assured[email]', this.memberType == "memberAssociateMember"?this.email.value:this.email1.value);
    this.formData.set('assured[assuredType]',this.memberType);
    this.formData.set('assured[gender]', this.memberType == "memberAssociateMember"?this.gender.value:"");
    this.formData.set('assured[coopNo]', this.quoteId>0? this.quoteData[0].coopNo: (this.coop_no.value? this.coop_no.value: this.User.coop_maincode));
    this.formData.set('assured[zipCode]', this.memberType == "memberAssociateMember"?this.zipCode.value:this.zipCode1.value);
    this.formData.set('assured[cid]', this.memberType == "memberAssociateMember"?this.cid.value: "");
    this.formData.set('assured[isQuote]', isQuote);
    this.formData.set('assured[branch]', this.memberType == "memberAssociateMember"?this.branch.value: this.branch1.value);
    this.formData.set('assured[prev_policy_no]', this.prev_policy_no);
    this.formData.set('assured[new_business]', this.new_business==true?'2' :'1');
    this.formData.set('assured[issuedFrom]', 'Web');
    this.formData.set('assured[remarks]', this.remarks.value);
    this.formData.set('assured[referer]', this.referer);
    this.formData.set('assured[id]', this.quoteId>0 && this.new_business==false ? this.quoteData[0].assuredListNo: null);
    this.formData.set('assured[quotationno]',this.quoteId>0 && this.new_business==false ? this.quoteData[0].id: null);
    this.formData.set('assured[policy]', this.productInfo.varshortname);
    this.formData.set('assured[variantId]', '1');
    this.formData.set('assured[posting_type]', this.posting_type);
    this.formData.set('building[buildingLocation]', this.buildingDetails.controls.buildingLocation.value);
    this.formData.set('building[buildingDescription]', this.buildingDetails.controls.BuildingDescription.value);
    this.formData.set('building[productNo]', this.buildingDetails.controls.riskClassification.value);
    this.formData.set('building[optionNo]', this.buildingDetails.controls.tarriffInterpret.value);
    this.formData.set('building[option2No]', this.buildingDetails.controls.zoneArea.value);
    this.formData.set('building[option3No]', this.buildingDetails.controls.classClassification.value);
    this.formData.set('building[tariffCode]', this.buildingDetails.controls.tariffCode.value);
    this.formData.set('building[tariffRate]', this.buildingDetails.controls.tariffRate.value);
    this.formData.set('building[tariffName]', this.buildingDetails.controls.tariffName.value);
    this.formData.set('building[buildingCoverage]', this.buildingDetails.controls.buildingCoverage.value.replace(/,/g, '') );
    this.formData.set('building[buildingInclusion]', this.occupancyDetails.value.buildingInclusion);
    this.formData.set('building[bounderiesFront]', this.occupancyDetails.value.bounderiesFront);
    this.formData.set('building[bounderiesLeft]', this.occupancyDetails.value.bounderiesLeft);
    this.formData.set('building[bounderiesRight]', this.occupancyDetails.value.bounderiesRight);
    this.formData.set('building[bounderiesRear]', this.occupancyDetails.value.bounderiesRear);
    this.formData.set('building[renew]', this.renew.value);
    this.formData.set('building[giventariffRate]', this.giventariffRate.value==''? this.tariffRates.value: this.giventariffRate.value);
    this.formData.set('assured[effectivityDate]', this.effectivity_date.value);
    this.formData.set('assured[expiryDate]', oneyear_date);
    this.formData.set('building[mortgaged]', this.mortgaged.value);
    this.formData.set('building[mortgagee]', this.mortgagee.value);
    this.formData.set('paymentHeader[grossPremium]', this.basicPremium);
    this.formData.set('paymentHeader[netPremium]', this.netPremium);
    this.formData.set('paymentHeader[waiveComm]', this.waivedCommission);
    this.formData.set('paymentHeader[coverage]', this.totalCoverage);
    this.formData.set('benefit', JSON.stringify(this.productInfo.benefit) );
    this.formData.set('paymentOther', JSON.stringify(this.productInfo.payment_list_rates) );
    this.formData.set('allContent', JSON.stringify(this.allContent) );
    this.formData.set('warrantiesAndClauses', JSON.stringify(this.productInfo.warranties_clauses));


    this.IssuePolicy.issuePolicy(this.formData).subscribe((res:any)=>{
      this.spinner.hide();

      Swal.fire(
        'Success!',
        res['msg'],
        'success'
      );

      if (this.User.usertype == 1) {
        this.router.navigate(['/admin/quotation-approval']);
      }

      else if (res.data.policyno){
        this.router.navigate(['/'+this.furl+'/policy-issued']);
      }else{
        this.router.navigate(['/'+this.furl+'/issued-policy-quotation']);
      }

    },error =>{

      this.formData.delete('requirements[]');
      this.spinner.hide();

      let err=error.status == 0?{msg: 'Something went wrong.'}: error.error ;

      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Error',
        msg,
        'error'
      );


      if(error.error.success){
        this.router.navigate(['/'+this.furl+'/issued-policy-quotation']);
  
      }
    })

  }




  // selectBenefit(){
  //   console.log(this.productInfo.warranties_clauses);

  // }


  

}
