import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services-http/user/user.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

  constructor(
    private userService: UserService, 
    private spinner: NgxSpinnerService
  ) { }

  user:any = {}
  userInfo = JSON.parse(localStorage.getItem('currentUser'));
  show = 'profile';
  passwordForm: FormGroup;
  loading:boolean= false;


  userForm = new FormGroup({
    firstname : new FormControl('', [Validators.required]),
    middlename : new FormControl('', [Validators.required]),
    lastname : new FormControl('', [Validators.required]),
    Username : new FormControl(''),
    Address : new FormControl(''),
    contact : new FormControl(''),
    created_date : new FormControl(''),
    maincode : new FormControl(''),
    loginId : new FormControl('')
  });

  ngOnInit() {
    setTimeout(() => this.spinner.show(), 0);

    this.loadUserProfile();
    this.passwordForm = new FormGroup({
      password : new FormControl('', [Validators.required] ),
      password_confirmation : new FormControl('', [Validators.required] )
    });
  }


  get password() {
    return this.passwordForm.get('password');
  }

  get password_confirmation() {
    return this.passwordForm.get('password_confirmation');
  }


  loadUserProfile() {
    this.userService.loadUser(this.userInfo.userId).subscribe(res => {
      this.user = res;

      console.log(this.user)

      this.userForm.patchValue({
        firstname : this.user.agent_coop == null? '' :this.user.agent_coop.firstname,
        middlename :  this.user.agent_coop == null? '' :this.user.agent_coop.middlename,
        lastname : this.user.agent_coop == null? '' :this.user.agent_coop.lastname,
        Username : this.user.username,
        Address : this.user.address,
        contact : this.user.mobile_no,
        created_date : this.user.date_inserted,
        maincode : this.user.coop.Name + ' - ' +this.user.coopnum,
        loginId : this.user.login_id
      });

      this.spinner.hide()
    })
  }


  update_password(){
    this.spinner.show()
   
    let data= {
      password: this.passwordForm.value.password,
      password_confirmation: this.passwordForm.value.password_confirmation,
      userId: this.userInfo.userId,
      username : this.user.username
    }

    this.userService.update_password(data).subscribe(res => {
      this.spinner.hide();

      this.passwordForm.reset();
      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );
      
    },error=>{
      this.spinner.hide();

      let err=error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Oppss',
        msg,
        'error'
      );
    });
  }


  updateUserInfo(){
    let data ={
      coop_maincode:this.user.coopnum,
      username:this.user.username,
      name:this.user.pkid_name,
      usertype:this.user.login_typeid,
      address:this.user.address,
      contact:this.userForm.value.contact,
      firstname:this.userForm.value.firstname,
      middlename:this.userForm.value.middlename,
      lastname:this.userForm.value.lastname,
      id:this.user.agent_coop==null?'': this.user.agent_coop.id,


    }

    this.userService.updateUserInfo(this.user.login_id, data).subscribe(res=>{
      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );
    });
  }

}
