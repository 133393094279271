// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
  production: false,
  url: 'https://coop-portal-api-stage.climbs.coop',
  whitelistedDomains: ['coop-portal-api-stage.climbs.coop'],
  // url: 'http://localhost:8000',
  // whitelistedDomains: ['localhost:8000'],
  tagumAPI: 'https://ictweb.tagumcooperative.coop/echoserver/mba',
  token_tagum: 'f/XnEfAgDq+mTDewBcPR1w==',
  ibisaAPI: 'https://climbs.api.ibisa.network',
  token_ibisa: 'ZYAeIefgz6CMpUArdZ5i0hOWK9kd03',
  tech_support_number: '09177035419/09178019789',
  tech_support_email: 'it_support@climbs.coop'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zongn eDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.




