import { Component, OnInit } from '@angular/core';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { BillingService } from '../../../services-http/billing/billing.service';
import { ProductService } from '../../../services-http/product/product.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-master-policy',
  templateUrl: './master-policy.component.html',
  styleUrls: ['./master-policy.component.scss']
})
export class MasterPolicyComponent implements OnInit {

  data:any = [];
  users = JSON.parse(localStorage.getItem('currentUser'));
  coop:any='';
  product:any='';
  search:any ='';
  productSelect:any=[];
  coopSelect:any=[];
  searchSubject = new Subject<string>();


  constructor(
    private issuedpolicy:IssuePolicyService,
    private spinner: NgxSpinnerService,
    private bill: BillingService,
    private prod:ProductService,
  ) { 

    this.searchSubject.pipe(
      debounceTime(1200)
    ).subscribe(searchValue => {
      this.getmasterPolicy(1);
    });
  }

  ngOnInit() {
    this.getmasterPolicy(1);

    this.adminTransactionHistoryCoop();

    this.getProduct();
  }


  onSearchChange(searchValue) {
    this.searchSubject.next(searchValue);
  }


  getmasterPolicy(page){
    this.spinner.show()
    let data ={
      coopno: this.coop,
      variantid: this.product,
      searchKey: this.search,
      page: page
    }

    this.issuedpolicy.masterPolicy(data)
      .subscribe(res=>{

        this.data = res;
        this.spinner.hide();
    },error=>{
        this.spinner.hide();
        Swal.fire(
          'Oppss',
          'Something went wrong!',
          'error'
        );
    });
  }


  export_master_policy(data){
    this.spinner.show();
    this.issuedpolicy.masterPolicyExport(data)
    .subscribe((res:any)=>{
      this.spinner.hide();

      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "master_policy.pdf";
      link.click();
  
  },error=>{
      this.spinner.hide();
      Swal.fire(
        'Oppss',
        'Something went wrong!',
        'error'
      );
  });
  }


  adminTransactionHistoryCoop(){
    this.bill.adminTransactionHistoryCoop().subscribe(res=>{

      this.coopSelect = res;
    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }

  getProduct(){
    setTimeout(() => this.spinner.show(), 0); 

    this.prod.getVariants().subscribe(res=>{
      this.productSelect =res;
      this.spinner.hide()

    },error=>{
      this.spinner.hide()

      Swal.fire(
        'Oopps!',
        'Something went wrong.',
        'error'
      );
    });
  }



}
