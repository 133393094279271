import { Component, OnInit, ViewChild  } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CacProductService } from '../../../services-http/cac-product/cac-product.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import { QuotationService } from '../../../services-http/quotation/quotation.service';
import { debounceTime } from "rxjs/operators";
import {environment} from '../../../../environments/environment';
import * as moment from 'moment';
import readXlsxFile from 'read-excel-file';

@Component({
  selector: 'app-cac-products',
  templateUrl: './cac-products.component.html',
  styleUrls: ['./cac-products.component.scss']
})
export class CacProductsComponent implements OnInit {
  @ViewChild('basicModal4') basicModal4: ModalDirective;
  @ViewChild('basicModal5') basicModal5: ModalDirective;
  @ViewChild('basicModal3') basicModal3: ModalDirective;

  items: MenuItem[];
  dialogIndex:number = 0;
  assuredDetailsMemberAssociate: FormGroup;
  assuredDetailsCorporate:FormGroup;
  benefitForm:FormGroup;
  memberType:any = "memberAssociateMember";
  selectedType:any;
  benefeciary:any = [];
  productId:any;
  productInfo:any={points:{credit:0,regular:0}};
  show:boolean =false;
  details:any = []
  User = JSON.parse(localStorage.getItem('currentUser'));
  waivedCommission:any = 0;
  totalCalCommission:number = 0;
  dateNow = new Date().getFullYear();
  birthdate:any;
  ageValidationMsg ="";
  declarationConfirm=false;
  paymentType:any= '';
  HDF:FormGroup;
  quoteId:any;
  CommissionPremiumByLoginType:any=[];
  url=this.router.url;
  furl;
  posting_type:any=0;
  clicked:boolean=false;
  paymentOption:any=[];
  netpremium:any=0;
  total_calc_commission:any=0;
  quoteData:any;
  unit:any=1;
  prev_policy_no:any ='';
  new_business:boolean = false; 
  lateReg:boolean =false;
  formData = new FormData();
  isLoading:boolean = false;
  searchitems:any={};
  searchListShow:boolean = false;
  searchResult:any = [];
  referer:any='';
  effectivity_date = moment(new Date).format('YYYY-MM-DD');


  optionsSelect = [
      { value: 'Monthly', label: 'Monthly', variantId: '6', option:99 },
      { value: 'Quarterly', label: 'Quarterly', variantId: '6', option:99 },
      { value: 'Semi-Annual', label: 'Semi-Annual', variantId: '6', option:99 },
      { value: 'OneTime', label: 'OneTime', variantId: '6', option:99 },
      { label: '5 Days', value: '5 Days', variantId: '66', option:150 },
      { label: '7 Days', value: '7 Days', variantId: '66', option:150 },
      { label: '10 Days', value: '10 Days', variantId: '66', option:150 },
      { label: '5 Days(opt2)', value: '5 Days(opt2)', variantId: '66', option:151 },
      { label: '7 Days(opt2)', value: '7 Days(opt2)', variantId: '66', option:151 },
      { label: '10 Days(opt2)', value: '10 Days(opt2)', variantId: '66', option:151 },
      { label: '10 Days(opt2)', value: '10 Days(opt2)', variantId: '66', option:151 },
      { label: 'Annual(15k)', value: 'Annual(15k)', variantId: '58', option:124 },
      { label: 'Annual(20k)', value: 'Annual(20k)', variantId: '58', option:125 },
      { label: 'Annual(25k)', value: 'Annual(25k)', variantId: '58', option:126 },
      { label: 'Annual(30k)', value: 'Annual(30k)', variantId: '58', option:127 },
      { label: 'Annual(35k)', value: 'Annual(35k)', variantId: '58', option:128 },
      { label: 'Annual(40k)', value: 'Annual(40k)', variantId: '58', option:129 },
      { label: 'Annual(45k)', value: 'Annual(45k)', variantId: '58', option:130 },
      { label: 'Annual(50k)', value: 'Annual(50k)', variantId: '58', option:131 },
      { label: 'Annual(55k)', value: 'Annual(55k)', variantId: '58', option:132 },
      { label: 'Annual(60k)', value: 'Annual(60k)', variantId: '58', option:133 },
      { label: 'Annual(65k)', value: 'Annual(65k)', variantId: '58', option:134 },
      { label: 'Annual(70k)', value: 'Annual(70k)', variantId: '58', option:135 },
      { label: 'Annual(75k)', value: 'Annual(75k)', variantId: '58', option:136 },
      { label: 'Annual(80k)', value: 'Annual(80k)', variantId: '58', option:137 },
      { label: 'Annual(85k)', value: 'Annual(85k)', variantId: '58', option:138 },
      { label: 'Annual(90k)', value: 'Annual(90k)', variantId: '58', option:139 },
      { label: 'Annual(95k)', value: 'Annual(95k)', variantId: '58', option:140 },
      { label: 'Annual(100k)', value: 'Annual(100k)', variantId: '58', option:141 },
    ];

 
    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private cacProduct:CacProductService,
      private datePipe: DatePipe,
      private spinner: NgxSpinnerService,
      private IssuePolicy: IssuePolicyService,
      private quotation: QuotationService,
    ) {
      route.params.subscribe(val => {
        this.quoteId=val.quoteId;
        this.dialogIndex = 0;
        this.productInfo={points:{credit:0,regular:0}};
        this.details = [];
        this.benefeciary = [];
        this.show = false;
        this.waivedCommission = 0;
        this.totalCalCommission = 0;
        this.productId =  val.id;
        this.ageValidationMsg='';

    });
  }

  validatePrincipalAge(){
 
    this.ageValidationMsg="";
    let age=this.dateNow - (new Date(this.assuredDetailsMemberAssociate.value.birthdate).getFullYear());
    let year= (new Date(this.assuredDetailsMemberAssociate.value.birthdate).getFullYear());


    if(this.productInfo.age_from&&this.productInfo.age_from){
      if(age<this.productInfo.age_from||age>this.productInfo.age_to ){

        this.ageValidationMsg = "Principal age must be "+parseFloat(this.productInfo.age_from).toFixed(0)+" to "+ parseFloat(this.productInfo.age_to).toFixed(0);
        return false;
      }
      return true;
    }


    if(this.productInfo.variantid=='8' && (year<1981 || year>1996)){
      this.ageValidationMsg = "Birth year must be from 1981-1996";
      return false;
    }

    return true;
  }


  ngOnInit() {
    this.getProductInfo();


    this.furl=this.url.split('/')[1];
    this.items = [
      {label: 'Assured Details'},
      {label: 'Option Details'},
      {label: 'Beneficiary'},
      {label: 'Payment'}
    ];

    this.assuredDetailsMemberAssociate = new FormGroup({
      // assured: new FormControl('memberAssociateMember', [Validators.required]),
      lastname: new FormControl('', Validators.required),
      firstname: new FormControl('', Validators.required),
      middlename: new FormControl('', Validators.required),
      suffix: new FormControl(''),
      gender: new FormControl('', Validators.required),
      contact: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(11)] ),
      birthdate: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      address: new FormControl('', Validators.required),
      occupation: new FormControl('', Validators.required),
      zipCode: new FormControl('', Validators.required),
      branch: new FormControl(''),
      cid: new FormControl(''),
      coop_no: new FormControl('')

    });
    this.assuredDetailsCorporate = new FormGroup({
      assuredName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      address: new FormControl('', [Validators.required]),
      contact: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(11)] ),
      zipCode: new FormControl('', [Validators.required]),
      branch: new FormControl('', [Validators.required]),
      coop_no: new FormControl('')


    });
    this.benefitForm = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      middlename: new FormControl('', Validators.required),
      birthdate: new FormControl('', Validators.required),
      relationship: new FormControl('', Validators.required),
    });
    this.HDF=new FormGroup({
      'ans1': new FormControl(0, Validators.required),
      'ans2': new FormControl(1, Validators.required),
      'ans3': new FormControl(0, Validators.required),
      'ans4': new FormControl(0, Validators.required),
      'ans5': new FormControl(0, Validators.required),
      'ans6': new FormControl(0, Validators.required),
      'ans7': new FormControl('', Validators.required),
      'ans1YesAnser': new FormControl(''),
      'ans3YesAnse': new FormControl(''),
      'ans6YesAnser': new FormControl('')
    });  



    // tagum cid
    this.assuredDetailsMemberAssociate.get("cid").valueChanges
      .pipe(debounceTime(3000))
      .subscribe(res => {
        if(res){
          const Http = new XMLHttpRequest();
          const url= environment.tagumAPI+'/get-profile/'+res;
          Http.open("GET", url);
          Http.setRequestHeader( 'Content-Type', 'application/json');
          Http.setRequestHeader("Authorization", `Bearer ${environment.token_tagum}`);
          Http.send();
  
          Http.onreadystatechange = (e)=>{
            this.benefeciary =[];
            let assured=JSON.parse(Http.response);

            let pipe = new DatePipe('en-US'); // Use your own locale
    
            if(Http.status==200){
              this.assuredDetailsMemberAssociate
                .patchValue({
                  lastname: assured.Message.appData.lastname,
                  firstname: assured.Message.appData.firstname,
                  middlename: assured.Message.appData.middlename,
                  suffix: assured.Message.appData.suffix,
                  gender: assured.Message.appData.gender.toLowerCase(),
                  contact: assured.Message.appData.contactno,
                  email:assured.Message.appData.email,
                  address: assured.Message.appData.address,
                  zipCode: assured.Message.appData.zipcode,
                  birthdate: pipe.transform(assured.Message.appData.birthday, 'yyyy-MM-dd'),
                  occupation: assured.Message.appData.occupation,
              });

              assured.Message.appData.dependents.forEach(row => {
                this.benefeciary.push({
                  lastname:row.deplname,
                  firstname:row.depfname,
                  middlename:row.depmname,
                  birthdate:pipe.transform(row.depbday, 'yyyy-MM-dd'),
                  relationship:row.relationship,
                });
              });
              
            }
            else{
              this.assuredDetailsMemberAssociate.reset();
              Swal.fire(
                'Oppss',
                assured.Message +' ('+ res +')',
                'error'
              );
            }  
          }
        }
    });



    this.assuredDetailsCorporate.get("coop_no").valueChanges
      .pipe(debounceTime(2000))
      .subscribe(res => {
        if(res && typeof(res)==='string'){
        this.search(res)
        }
    });


    this.assuredDetailsMemberAssociate.get("coop_no").valueChanges
    .pipe(debounceTime(2000))
    .subscribe(res => {
      if(res && typeof(res)==='string'){
      this.search(res)
      }
    });
  }


  getBenefits(beneficiaryType){

    return this.details.option_benefit.filter(x=>x.beneficiary == +beneficiaryType);
  }

  

  
  closeSearchList(){
    this.searchListShow = false;
  }

  searchItem(searhItem){

    this.searchListShow = false;
    this.searchitems = searhItem;
    this.assuredDetailsMemberAssociate.patchValue({coop_no: searhItem.CoopNum});
    this.assuredDetailsCorporate.patchValue({coop_no: searhItem.CoopNum});

    this.assuredDetailsCorporate.patchValue({assuredName: searhItem.Name});

  }
  search(res){
    this.isLoading = true;

    let data = {
      search:res
    }
        
    this.cacProduct.searchGroupClient(data).subscribe((res:any)=>{
      this.isLoading = false;
     
      this.searchListShow = true;  
      this.searchResult = res;  
    },error=>{
      this.isLoading = false;

      Swal.fire(
        'Oppss',
        'Something went wrong.',
        'error'
      );
    });
  }



  async viewPolicyDetails(){
    this.quoteData = await this.quotation.view_quotation(this.quoteId).toPromise();

    this.new_business = this.quoteData[0].statusid==7? true: false;

    this.prev_policy_no = this.quoteData[0].policyno;
    
    this.memberType=this.quoteData[0].assured.assuredType;

    let ef = new Date();
    let effdate = moment(ef).format('YYYY-MM-DD');

    this.effectivity_date = effdate;

    if(this.quoteData[0].assured.assuredType=="memberAssociateMember"){
      this.assuredDetailsMemberAssociate.patchValue({
        lastname  :this.quoteData[0].assured.lastName,
        firstname :this.quoteData[0].assured.firstName,
        middlename:this.quoteData[0].assured.middleName,
        suffix    :this.quoteData[0].assured.suffix,
        gender    :this.quoteData[0].gender,
        contact   :this.quoteData[0].contactNo,
        birthdate :this.quoteData[0].birthday,
        email     :this.quoteData[0].emailAddress,
        address   :this.quoteData[0].address,
        occupation:this.quoteData[0].occupation,
        zipCode   :this.quoteData[0].zipCode,
        coop_no   :+this.quoteData[0].coopNo,         
      });
    }
    if(this.quoteData[0].assured.assuredType=="corporate"||this.quoteData[0].assured.assuredType=="cooperative"){
      this.assuredDetailsCorporate.patchValue({
        assuredName :this.quoteData[0].assured.assuredName,
        email       :this.quoteData[0].emailAddress,
        address     :this.quoteData[0].address,
        contact     :this.quoteData[0].contactNo,
        zipCode     :this.quoteData[0].zipCode,
        coop_no     :+this.quoteData[0].coopNo  
      });
    }
    this.selectedType =this.quoteData[0].option.prodoptid; 

    this.showDetails();

    this.quoteData[0].beneficiary.forEach(row => {
      this.benefeciary.push({
        firstname: row.firstName,
        lastname: row.lastName,
        middlename: row.middleName,
        birthdate: row.birthday,
        relationship: row.relationship,
      });
    });

    
  }


  getProductInfo(){
    let data={
      variantId: this.productId,
      login_id : this.User.iloginId
    }
    this.IssuePolicy.getProductInfo(data).subscribe((res:any)=>{
      
      if(this.quoteId>0){
        this.viewPolicyDetails();
      }

      this.productInfo=res;
      if(this.productInfo.canSave=="0"){
        Swal.fire(
          'Alert',
          'This product '+this.productInfo.varlongname+' is currently disabled.',
          'warning'
        );
      }
    },error=>{
      Swal.fire(
        'Oppss',
        'Something went wrong.',
        'error'
      );
    });
  }



  nextPage(){
    if(this.validatePrincipalAge()==true){
      this.dialogIndex++;
    }
  }

  previousPage(){
    this.dialogIndex--;
  }

  addBenefit(){
    this.benefeciary.push(this.benefitForm.value);
  }
  
  // groupedOptionBenefits = {};

  showDetails(){
    this.show = true;
    this.details=this.productInfo.prod[0].option.find(x =>x.id==this.selectedType);

    if(this.productInfo.variantid=='6'||this.productInfo.variantid=='66'||this.productInfo.variantid=='58'&&this.validatePrincipalAge()==true ){
      this.paymentOption=this.optionsSelect.filter(x=>x.variantId==this.productInfo.variantid && x.option==this.selectedType);

      this.basicModal4.show();
    }
  }

  beneficiary_prem:any=0;

  
  compute(){
    let o_pay_total:any=0;
    this.beneficiary_prem =0;
  
    if(this.benefeciary.length>4 &&this.productInfo.variantid==58){
      this.beneficiary_prem  =(this.benefeciary.length-4) * this.details.premAdd;
      
    }
   
    this.productInfo.payment_list_rates.forEach(element => {
       o_pay_total += +(element.paymentListNo==5||element.paymentListNo==2? ((this.details.grossprem*this.unit) +this.beneficiary_prem) * (element.rate/100) :element.rate);
    });
    this.netpremium = o_pay_total + (+this.details.grossprem*this.unit) +this.beneficiary_prem;

    // this.totalCalCommission = (this.details.grossprem*this.unit) * (this.productInfo.prod[0].commission[0].reduce((prev,next)=>+prev+ +next.comm_rate_max,0)/100);

    this.totalCalCommission = ((this.details.grossprem*this.unit) +this.beneficiary_prem) * (+this.productInfo.prod[0].commission[0].comm_rate_max/100);

  }


  netCommision(){
    if(this.waivedCommission > this.totalCalCommission ){
      this.waivedCommission=0;
      Swal.fire(
        'Note!',
        'waived commission max is ' +this.totalCalCommission,
        'warning'
      );
    }
    else{
      this.basicModal3.hide();
    } 
  }


  onUploadOutput(event){
    this.formData.set('requirements[]', event.target.files[0], event.target.files[0].name);
  }


  async issuePolicy(isQuote){
    this.clicked = true;
    this.spinner.show();

    let hdf={
      'ans1Label': this.HDF.value.ans1=='1'?'Yes':'No',
      'ans2Label': this.HDF.value.ans2=='1'?'Yes':'No',
      'ans3Label': this.HDF.value.ans3=='1'?'Yes':'No',
      'ans4Label': this.HDF.value.ans4=='1'?'Yes':'No',
      'ans5Label': this.HDF.value.ans5=='1'?'Yes':'No',
      'ans6Label': this.HDF.value.ans6=='1'?'Yes':'No',
    }

    let ef = new Date(this.effectivity_date)
    let oneyear = ef.setDate(ef.getDate()+365);
    let oneyear_date = moment(oneyear).format('YYYY-MM-DD');


    this.formData.set('assured[assuredName]', this.memberType == "memberAssociateMember"? this.assuredDetailsMemberAssociate.value.firstname +' '+ this.assuredDetailsMemberAssociate.value.middlename +' '+ this.assuredDetailsMemberAssociate.value.lastname: this.assuredDetailsCorporate.value.assuredName),
    this.formData.set('assured[createdBy]', this.quoteId>0? this.quoteData[0].agentNo: this.User.iloginId);
    this.formData.set('assured[firstName]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.firstname:"");
    this.formData.set('assured[lastName]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.lastname:"");
    this.formData.set('assured[middleName]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.middlename:"");
    this.formData.set('assured[suffix]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.suffix:"");
    this.formData.set('assured[address]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.address:this.assuredDetailsCorporate.value.address);
    this.formData.set('assured[contact]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.contact:this.assuredDetailsCorporate.value.contact);
    this.formData.set('assured[email]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.email:this.assuredDetailsCorporate.value.email);
    this.formData.set('assured[assuredType]', this.memberType);
    this.formData.set('assured[gender]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.gender:"")
    this.formData.set('assured[birthdate]', this.memberType == "memberAssociateMember"? this.assuredDetailsMemberAssociate.value.birthdate:"")
    this.formData.set('assured[occupation]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.occupation:"")
    this.formData.set('assured[coopNo]', this.quoteId>0? this.quoteData[0].coopNo: (this.assuredDetailsCorporate.value.coop_no? this.assuredDetailsCorporate.value.coop_no: this.User.coop_maincode));
    this.formData.set('assured[zipCode]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.zipCode:this.assuredDetailsCorporate.value.zipCode);
    this.formData.set('assured[posting_type]', this.posting_type);
    this.formData.set('assured[branch]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.branch: this.assuredDetailsCorporate.value.branch);
    this.formData.set('assured[cid]', this.memberType == "memberAssociateMember"?this.assuredDetailsMemberAssociate.value.cid:"");
    this.formData.set('assured[issuedFrom]', 'Web');
    this.formData.set('assured[isQuote]', isQuote);
    this.formData.set('assured[new_business]', this.new_business==true?'2' :'1');
    this.formData.set('assured[prev_policy_no]', this.prev_policy_no);
    this.formData.set('assured[effectivityDate]', this.effectivity_date);
    this.formData.set('assured[expiryDate]', oneyear_date);
    this.formData.set('assured[id]', this.quoteId>0 && this.new_business==false ? this.quoteData[0].assuredListNo:'');
    this.formData.set('assured[quotationno]', this.quoteId>0 && this.new_business==false ?this.quoteData[0].id:'');
    this.formData.set('assured[referer]',this.referer);
    this.formData.set('assured[variantId]', this.productInfo.variantid);
    this.formData.set('assured[policy]', this.productInfo.varshortname);
    this.formData.set('assured[remarks]', '');
    this.formData.set('policyHeader[policy]', this.productInfo.varshortname);
    this.formData.set('policyHeader[variantid]', this.productInfo.variantid);
    this.formData.set('policyHeader[productNo]', this.details.productId);
    this.formData.set('policyHeader[units]', this.unit);
    this.formData.set('option[prodoptid]', this.details.id);
    this.formData.set('beneficiary', JSON.stringify(this.benefeciary) );
    this.formData.set('paymentHeader[netPremium]', this.netpremium);
    this.formData.set('paymentHeader[waiveComm]', this.waivedCommission);
    this.formData.set('paymentHeader[grossPremium]', (this.details.grossprem*this.unit)+this.beneficiary_prem);
    this.formData.set('paymentOther', JSON.stringify(this.productInfo.payment_list_rates));
    this.formData.set('hdf', JSON.stringify(this.productInfo.classification==1? Object.assign(this.HDF.value, hdf):''));


    this.IssuePolicy.issuePolicy(this.formData).subscribe((res:any)=>{
      this.spinner.hide();
      
      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );

      if(res.data.policyno){
        this.router.navigate(['/'+this.furl+'/policy-issued']);
      } else{
        this.router.navigate(['/'+this.furl+'/issued-policy-quotation']);
      }
      
    },error=>{
      this.clicked=false;
      this.spinner.hide();

      
      let err=error.status == 0?{msg: 'Something went wrong.'}: error.error ;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Error',
        msg,
        'error'
      );
    });
  }

 

  getGrossPrem3sure(){
    let age= this.dateNow - (new Date(this.assuredDetailsMemberAssociate.value.birthdate).getFullYear());

    if (this.paymentType == 'Monthly') {
      if (age > 18 && age < 45) {
        this.details.grossprem  = 747.00;
      }
      else if (age > 46 && age < 60) {
        this.details.grossprem  = 1008.00;
      }
    }
    
    else if (this.paymentType == 'Quarterly') {
      if (age > 18 && age < 45) {
        this.details.grossprem  = 2199.50;
      }
      else if (age > 46 && age < 60) {
        this.details.grossprem  = 2968.00;
      }
    }

    else if (this.paymentType == 'Semi-Annual') {
      if (age > 18 && age < 45) {
        this.details.grossprem  = 4316.00;
      }
      else if (age > 46 && age < 60) {
        this.details.grossprem  = 5824.00;
      }
    }

    else if (this.paymentType == 'OneTime') {
      if (age > 18 && age < 45) {
        this.details.grossprem  = 8300.00;
      }
      else if (age > 46 && age < 60) {
        this.details.grossprem  = 11200.00;
      }
    }

    else if (this.paymentType == '5 Days' ) {
      this.details.grossprem  = 122.92;
    }

    else if (this.paymentType == '7 Days' ) {
      this.details.grossprem  = 157.17;
    }

    else if (this.paymentType == '10 Days' ) {
      this.details.grossprem  = 205.53;
    }

    else if (this.paymentType == '5 Days(opt2)' ) {
      this.details.grossprem  = 159.19;
    }

    else if (this.paymentType == '7 Days(opt2)' ) {
      this.details.grossprem  = 192.43;
    }

    else if (this.paymentType == '10 Days(opt2)' ) {
      this.details.grossprem  = 291.17;
    }

    else if (this.paymentType == 'Annual(15k)' ) {
      this.details.grossprem  = 780.00;
      this.details.premAdd  = 71.50;
    }
    // else if (this.paymentType == 'Semi-Annual(15k)' ) {
    //   this.details.grossprem  = 405.60;
    // }
    // else if (this.paymentType == 'Quarterly(15k)' ) {
    //   this.details.grossprem  = 206.70;
    // }
    else if (this.paymentType == 'Annual(20k)' ) {
      this.details.grossprem  = 1040.00;
       this.details.premAdd  = 95.33;
    }
    // else if (this.paymentType == 'Semi-Annual(20k)' ) {
    //   this.details.grossprem  = 540.80;
    // }
    // else if (this.paymentType == 'Quarterly(20k)' ) {
    //   this.details.grossprem  = 275.60;
    // }
    else if (this.paymentType == 'Annual(25k)' ) {
      this.details.grossprem  = 1300.00;
       this.details.premAdd  = 119.17;
    }
    // else if (this.paymentType == 'Semi-Annual(25k)' ) {
    //   this.details.grossprem  = 676.00;
    // }
    // else if (this.paymentType == 'Quarterly(25k)' ) {
    //   this.details.grossprem  = 344.50;
    // }
    else if (this.paymentType == 'Annual(30k)' ) {
      this.details.grossprem  = 1560.00;
       this.details.premAdd  = 143.00;
    }
    // else if (this.paymentType == 'Semi-Annual(30k)' ) {
    //   this.details.grossprem  = 811.20;
    // }
    // else if (this.paymentType == 'Quarterly(30k)' ) {
    //   this.details.grossprem  = 413.40;
    // }
    else if (this.paymentType == 'Annual(35k)' ) {
      this.details.grossprem  = 1820.00;
       this.details.premAdd  = 166.83;
    }
    // else if (this.paymentType == 'Semi-Annual(35k)' ) {
    //   this.details.grossprem  = 946.40;
    // }
    // else if (this.paymentType == 'Quarterly(35k)' ) {
    //   this.details.grossprem  = 482.30;
    // }
    else if (this.paymentType == 'Annual(40k)' ) {
      this.details.grossprem  = 2080.00;
       this.details.premAdd  = 190.67;
    }
    // else if (this.paymentType == 'Semi-Annual(40k)' ) {
    //   this.details.grossprem  = 1081.60;
    // }
    // else if (this.paymentType == 'Quarterly(40k)' ) {
    //   this.details.grossprem  = 551.20;
    // }
    else if (this.paymentType == 'Annual(45k)' ) {
      this.details.grossprem  = 2340.00;
       this.details.premAdd  = 214.50;
    }
    // else if (this.paymentType == 'Semi-Annual(45k)' ) {
    //   this.details.grossprem  = 1216.80;
    // }
    // else if (this.paymentType == 'Quarterly(45k)' ) {
    //   this.details.grossprem  = 620.10;
    // }
    else if (this.paymentType == 'Annual(50k)' ) {
      this.details.grossprem  = 2600.00;
       this.details.premAdd  = 238.33;
    }
    // else if (this.paymentType == 'Semi-Annual(50k)' ) {
    //   this.details.grossprem  = 1352.00;
    // }
    // else if (this.paymentType == 'Quarterly(50k)' ) {
    //   this.details.grossprem  = 689.00;
    // }
    else if (this.paymentType == 'Annual(55k)' ) {
      this.details.grossprem  = 2860.00;
       this.details.premAdd  = 262.17;
    }
    // else if (this.paymentType == 'Semi-Annual(55k)' ) {
    //   this.details.grossprem  = 1487.20;
    // }
    // else if (this.paymentType == 'Quarterly(55k)' ) {
    //   this.details.grossprem  = 757.90;
    // }
    else if (this.paymentType == 'Annual(60k)' ) {
      this.details.grossprem  = 3120.00;
       this.details.premAdd  = 286.00;
    }
    // else if (this.paymentType == 'Semi-Annual(60k)' ) {
    //   this.details.grossprem  = 1622.40;
    // }
    // else if (this.paymentType == 'Quarterly(60k)' ) {
    //   this.details.grossprem  = 826.80;
    // }
    else if (this.paymentType == 'Annual(65k)' ) {
      this.details.grossprem  = 3380.00;
       this.details.premAdd  = 309.83;
    }
    // else if (this.paymentType == 'Semi-Annual(65k)' ) {
    //   this.details.grossprem  = 1757.60;
    // }
    // else if (this.paymentType == 'Quarterly(65k)' ) {
    //   this.details.grossprem  = 895.70;
    // }
    else if (this.paymentType == 'Annual(70k)' ) {
      this.details.grossprem  = 3640.00;
       this.details.premAdd  = 333.67;
    }
    // else if (this.paymentType == 'Semi-Annual(70k)' ) {
    //   this.details.grossprem  = 1892.80;
    // }
    // else if (this.paymentType == 'Quarterly(70k)' ) {
    //   this.details.grossprem  = 964.60;
    // }
    else if (this.paymentType == 'Annual(75k)' ) {
      this.details.grossprem  = 3900.00;
       this.details.premAdd  = 357.50;
    }
    // else if (this.paymentType == 'Semi-Annual(75k)' ) {
    //   this.details.grossprem  = 2028.00;
    // }
    // else if (this.paymentType == 'Quarterly(75k)' ) {
    //   this.details.grossprem  = 1033.50;
    // }
    else if (this.paymentType == 'Annual(80k)' ) {
      this.details.grossprem  = 4160.00;
       this.details.premAdd  = 381.33;
    }
    // else if (this.paymentType == 'Semi-Annual(80k)' ) {
    //   this.details.grossprem  = 2163.20;
    // }
    // else if (this.paymentType == 'Quarterly(80k)' ) {
    //   this.details.grossprem  = 1102.40;
    // }
    else if (this.paymentType == 'Annual(85k)' ) {
      this.details.grossprem  = 4420.00;
       this.details.premAdd  = 405.17;
    }
    // else if (this.paymentType == 'Semi-Annual(85k)' ) {
    //   this.details.grossprem  = 2298.40;
    // }
    // else if (this.paymentType == 'Quarterly(85k)' ) {
    //   this.details.grossprem  = 1171.30;
    // }
    else if (this.paymentType == 'Annual(90k)' ) {
      this.details.grossprem  = 4680.00;
       this.details.premAdd  = 429.00;
    }
    // else if (this.paymentType == 'Semi-Annual(90k)' ) {
    //   this.details.grossprem  = 2433.60;
    // }
    // else if (this.paymentType == 'Quarterly(90k)' ) {
    //   this.details.grossprem  = 1240.20;
    // }
    else if (this.paymentType == 'Annual(95k)' ) {
      this.details.grossprem  = 4940.00;
       this.details.premAdd  = 452.83;
    }
    // else if (this.paymentType == 'Semi-Annual(95k)' ) {
    //   this.details.grossprem  = 2568.80;
    // }
    // else if (this.paymentType == 'Quarterly(95k)' ) {
    //   this.details.grossprem  = 1309.10;
    // }
    else if (this.paymentType == 'Annual(100k)' ) {
      this.details.grossprem  = 5200.00;
       this.details.premAdd  = 476.67;
    }
    // else if (this.paymentType == 'Semi-Annual(100k)' ) {
    //   this.details.grossprem  = 2704.00;
    // }
    // else if (this.paymentType == 'Quarterly(100k)' ) {
    //   this.details.grossprem  = 1378.00;
    // }

    this.basicModal4.hide();
  }



  
  lateRegchange(){

    if(!this.lateReg){
      this.assuredDetailsMemberAssociate.controls.effectivity_date.setValue('');
      // this.assuredDetailsCorporate.controls.effectivity_date.setValue('');
    }

    // if(this.memberType=='memberAssociateMember'){
      // this.assuredDetailsMemberAssociate.controls.effectivity_date.setValidators(this.lateReg==false ? null : [Validators.required]);
    // }
    // else{
    //   this.assuredDetailsCorporate.controls.effectivity_date.setValidators(this.lateReg==false ? null : [Validators.required]);
    // }
   
    // this.assuredDetailsMemberAssociate.controls.effectivity_date.updateValueAndValidity();
    // this.assuredDetailsCorporate.controls.effectivity_date.updateValueAndValidity();

  }



  upload_beneficiary(){
    const input:any = document.getElementById('file-upload')

    readXlsxFile(input.files[0]).then((rows:any) => {
      console.log(rows)
      rows.forEach(row => {
        this.benefeciary.push({
          lastname: row[0],
          firstname: row[1],
          middlename: row[2],
          birthdate: row[3],
          relationship: row[4]
        });
      });
    },error=>{

      Swal.fire(
        'Error',
        'Only accept .xlsx file.',
        'error'
      );
    });
  }

}
